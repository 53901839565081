import Box from '@mui/material/Box';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useRouter } from 'next/router';
import type { ReactNode } from 'react';
import React, { useContext } from 'react';

import DialogSwitcher from '@/contents/Dialogs/DialogSwitcher';
import BreadCrumb from '@/contents/General/BreadCrumb';
import Stack from '@/contents/General/Stack';
import { GlobalContext } from '@/contents/hooks/state';
import { BottomMenu } from '@/layouts/components/BottomMenu';
import { Footer } from '@/layouts/components/Footer';
import { GreenHeader } from '@/layouts/components/GreenHeader';
import { Header } from '@/layouts/components/Header';
import { Container, PCArea } from '@/layouts/style';
import theme from '@/styles/theme';
import { Page } from '@/types/domain';

import PCNavi from './components/PCNavi';
import PCSuggest from './components/PCSuggest';

type Props = {
  gacha?: any;
  children: ReactNode;
};

const Layout = ({ children, gacha }: Props) => {
  const { page } = useContext(GlobalContext);
  // @ts-ignore
  const { props } = children;

  const isPC = useMediaQuery('(min-width:900px)');
  const router = useRouter();

  return (
    <>
      <Box>
        {isPC && (
          <PCArea>
            <Stack between>
              <PCNavi />
              <PCSuggest />
            </Stack>
          </PCArea>
        )}
        <Container>
          {!router.pathname.startsWith('/present') ? (
            <Header />
          ) : (
            <GreenHeader />
          )}

          <main>
            {children}
            <Box
              sx={{
                marginTop: 'auto',
                marginBottom: theme.spacing(3),
                padding:
                  page === Page.THEME
                    ? `0 ${theme.spacing(3)}`
                    : `${theme.spacing(10)} ${theme.spacing(3)} 0`,
              }}
            >
              <BreadCrumb
                campSite={props?.campSite}
                user={props?.user}
                notification={props?.notification}
                post={props?.post}
                faq={props?.faq}
                faqCategory={props?.category}
                theme={props?.theme}
                gacha={gacha}
              />
            </Box>
            <Footer />
          </main>
          {!isPC && page !== Page.CAMPSITE && <BottomMenu />}
          <DialogSwitcher />
        </Container>
      </Box>
    </>
  );
};

export default Layout;
