import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import { useRouter } from 'next/router';
import { useContext, useEffect } from 'react';

import GiveMeAHand from '@/contents/CampSite/other/GetMeAHand';
import Button from '@/contents/General/Button';
import Stack from '@/contents/General/Stack';
import Typography from '@/contents/General/Typography';
import { GlobalContext } from '@/contents/hooks/state';
import Layout from '@/layouts';
import theme from '@/styles/theme';
import type { NextPageWithLayout } from '@/types';
import { Page } from '@/types/domain';

const Container = styled(Box)`
  position: relative;
  padding: 0 ${theme.spacing(3)};
`;

const TokushohoPage: NextPageWithLayout = () => {
  const router = useRouter();
  const { setPage } = useContext(GlobalContext);

  useEffect(() => {
    setPage(Page.TERMS);
  }, []);

  return (
    <>
      <Container>
        <Box paddingTop={5} paddingBottom={5}>
          <Typography
            fontSize={21}
            fontWeight={700}
            textAlign="center"
            paddingBottom={4}
          >
            お探しのページが
            <br />
            見つかりませんでした。
          </Typography>
          <Typography fontSize={15} lineHeight={1.8}>
            アクセスしようとしたページは削除・変更されたか、現在利用できない可能性があります。お手数ですが、iihiホームページのトップまたはメニューよりお探しください。
          </Typography>
        </Box>
        <Stack center paddingBottom={7}>
          <Button
            onClick={() => router.push('/')}
            buttonSize="md"
            sx={{ width: '246px' }}
          >
            トップページに戻る
          </Button>
        </Stack>
      </Container>
      <GiveMeAHand />
    </>
  );
};

TokushohoPage.getLayout = (page) => <Layout>{page}</Layout>;

export default TokushohoPage;
