import { useMediaQuery } from '@mui/material';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import { useRouter } from 'next/router';
import React from 'react';

import Button from '@/contents/General/Button';
import NotificationArea from '@/contents/General/NotificationArea';
import theme from '@/styles/theme';

const Container = styled(Box)`
  position: fixed;
  width: 100%;
  max-width: inherit;
  z-index: 11;
` as typeof Box;

const HeaderArea = styled(Box)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 ${theme.spacing(2)};
  width: inherit;
  height: 64px;
  max-width: inherit;
  background: #0a6058;
  @media (min-width: 900px) {
    display: none;
  }
`;

export const GreenHeader = () => {
  const router = useRouter();

  const isPC = useMediaQuery('(min-width:900px)');

  return (
    <Container sx={isPC ? { mt: '-1px' } : {}}>
      <NotificationArea />
      <HeaderArea>
        <Button onClick={() => router.push('/')} component="label">
          <img
            src="/assets/images/common/logo-header-white.svg"
            width={121}
            height={34}
            alt="iihi logo"
          />
        </Button>
      </HeaderArea>
    </Container>
  );
};
